<template lang="pug">
#confirmation-dialog.video-step-dialog.is-fullscreen
  .modal-text
    icon.cd-icon(v-if="iconPath", :data="iconPath", original="true")
    h1 {{ prompt }}
  .modal-footer
    button.button.action-btn(@click="performAction") {{ confirmBtn }}
    button.button.cancel-btn(@click="denyHandler") {{ denyBtn }}
</template>

<script>
export default {
  props: {
    icon: { type: String, required: true },
    prompt: { type: String, required: true },
    confirmBtn: { type: String, required: false, default: 'Yes' },
    denyBtn: { type: String, required: false, default: 'No' },
    denyModal: { type: String, required: false },
    onAction: { type: Function, required: true },
  },

  data() {
    return {
      iconPath: null,
    }
  },

  computed: {},

  watch: {},

  created() {
    this.iconPath = require('@icon/' + this.icon)
  },

  methods: {
    close,
    denyHandler,
    performAction,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function close() {
  this.$modal.close('ConfirmationDialog')
}

function denyHandler() {
  if (this.denyModal) {
    this.$modal.open(this.denyModal)
  } else {
    this.close()
  }
}

function performAction() {
  this.onAction()
  this.close()
}
</script>
